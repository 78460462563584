<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
    v-if="isDataLoadedFromServer"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Edit warehouse</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <div class="row">
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-autocomplete
              v-model="formData.organization_id"
              label="Organization"
              item-text="text"
              item-value="index"
              :items="serverData.organizations"
              clearable
              outlined
              dense
              :error-messages="organization_idErrors"
              @input="$v.formData.organization_id.$touch()"
              @blur="$v.formData.organization_id.$touch()"
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-autocomplete
              v-model="formData.warehouse_type_id"
              label="Warehouse type"
              item-text="text"
              item-value="index"
              :items="serverData.warehouse_types"
              clearable
              outlined
              dense
              :error-messages="warehouse_type_idErrors"
              @input="$v.formData.warehouse_type_id.$touch()"
              @blur="$v.formData.warehouse_type_id.$touch()"
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.system_code"
              label="System code"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.warehouse_name"
              label="Warehouse name"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 my-0 py-0">
            <v-text-field
              v-model="formData.description"
              label="Description"
              clearable
              outlined
              dense
              :error-messages="descriptionErrors"
              @input="$v.formData.description.$touch()"
              @blur="$v.formData.description.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 d-flex align-center py-0 my-0 h-65px">
            <h3 class="pl-2 font-weight-regular">Status</h3>
            <div class="col-4 col-sm-2"></div>
            <v-switch
              :label="statusLabel"
              v-model="formData.status"
              class="mt-0 pt-0"
            />
          </div>

          <div class="col-12 pt-4"><h3 class="">Address</h3></div>

          <div class="col-12 col-sm-6 my-0 py-0">
            <v-autocomplete
              @change="onCountryChange"
              v-model="$v.formData.country_id.$model"
              label="Country"
              item-text="text"
              item-value="index"
              :items="serverData.countries"
              clearable
              outlined
              dense
              :error-messages="country_idErrors"
              @input="$v.formData.country_id.$touch()"
              @blur="$v.formData.country_id.$touch()"
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-autocomplete
              @change="onStateChange"
              :disabled="isStatesLoaded"
              v-model="$v.formData.state_id.$model"
              label="State"
              item-text="title"
              item-value="id"
              :items="serverData.states"
              clearable
              outlined
              dense
              :error-messages="state_idErrors"
              @input="$v.formData.state_id.$touch()"
              @blur="$v.formData.state_id.$touch()"
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-autocomplete
              @change="onCityChange"
              :disabled="isCitiesLoaded"
              v-model="$v.formData.city_id.$model"
              label="City"
              item-text="title"
              item-value="id"
              :items="serverData.cities"
              clearable
              outlined
              dense
              :error-messages="city_idErrors"
              @input="$v.formData.city_id.$touch()"
              @blur="$v.formData.city_id.$touch()"
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-autocomplete
              :disabled="isAreasLoaded"
              v-model="formData.area_id"
              label="Area"
              item-text="title"
              item-value="id"
              :items="serverData.areas"
              clearable
              outlined
              dense
            ></v-autocomplete>
          </div>

          <div class="col-12 my-0 py-0">
            <v-text-field
              v-model="formData.address"
              label="Address"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-8 my-0 py-0">
            <v-text-field
              v-model="formData.address_2"
              label="Address 2"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.postal_code"
              label="Postal code"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.contact_name"
              label="Contact name"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.contact_phone"
              label="Contact phone"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.contact_email"
              label="Contact email"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
        </div>
        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center pt-6">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import warehouseEditMixin from "@/own/mixins/warehouseEditMixin";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  name: "EditWarehouse",
  props: ["id", "editType"],
  mixins: [warehouseEditMixin, validationMixin],
  validations() {
    return {
      formData: {
        country_id: { required },
        state_id: { required },
        city_id: { required },
        warehouse_type_id: { required },
        organization_id: {
          required: requiredIf(function () {
            return !!this.serverData?.organizations;
          }),
        },
        description: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      warehouse_type_id: null,
      organization_id: null,
      system_code: null,
      country_id: null,
      warehouse_name: null,
      description: null,
      address: null,
      address_2: null,
      postal_code: null,
      city_id: null,
      state_id: null,
      area_id: null,
      contact_name: null,
      surname: null,
      contact_phone: null,
      contact_email: null,
      status: null,
    },
  }),
  computed: {
    statusLabel() {
      return this.formData.status ? "Active" : "In active";
    },
    states: function () {
      return this.serverData.states;
    },
    cities: function () {
      return this.serverData.cities;
    },
    areas: function () {
      return this.serverData.areas;
    },
    isStatesLoaded: function () {
      return !this.serverData.states;
    },
    isCitiesLoaded: function () {
      return !this.serverData.cities;
    },
    isAreasLoaded: function () {
      return !this.serverData.areas;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },

    country_idErrors: function () {
      return this.handleFormValidation("country_id", this);
    },
    descriptionErrors: function () {
      return this.handleFormValidation("description", this);
    },
    state_idErrors: function () {
      return this.handleFormValidation("state_id", this);
    },
    city_idErrors: function () {
      return this.handleFormValidation("city_id", this);
    },
    warehouse_type_idErrors: function () {
      return this.handleFormValidation("warehouse_type_id", this);
    },
    organization_idErrors: function () {
      return this.handleFormValidation("organization_id", this);
    },
  },
  methods: {
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    submitCreateForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      let sendData = {
        type: `${this.editType}`,
        ...this.formData,
      };
      if (!this.serverData.organizations) {
        sendData.organization_id =
          this.$store.getters.currentUser.data.organization_id;
      }
      this.sentToApi(sendData);
    },
    async resetCreateForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.value };
        await Object.entries(this.formData).forEach((localD) => {
          Object.entries(this.serverData.value).forEach((serverD) => {
            if (localD[0] === serverD[0] && localD[0] !== "image") {
              this.formData[localD[0]] = copy[serverD[0]];
            }
          });
        });
        if (this.serverData.value.country_id) {
          await this.onCountryChange(this.serverData.value.country_id, true);
        }
        if (this.serverData.value.state_id) {
          await this.onStateChange(this.serverData.value.state_id, true);
        }
        if (this.serverData.value.city_id) {
          await this.onCityChange(this.serverData.value.city_id);
        }
      } else {
        this.formData = {
          id: null,
          warehouse_type_id: null,
          organization_id: null,
          system_code: null,
          country_id: null,
          warehouse_name: null,
          description: null,
          address: null,
          address_2: null,
          postal_code: null,
          city_id: null,
          state_id: null,
          area_id: null,
          contact_name: null,
          surname: null,
          contact_phone: null,
          contact_email: null,
          status: null,
        };
      }

      // this.serverData = null;
    },

    async onCountryChange(val, safe = false) {
      if (val) {
        this.$store.commit(SET_PAGE_LOADING, true);
        let data = { country: val };
        await ApiService.post("/address/states/search", data)
          .then((response) => {
            this.serverData.states = response.data.states;
            if (!safe) {
              this.formData.city_id = null;
              this.formData.area_id = null;
              this.serverData.cities = null;
              this.serverData.areas = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.$store.commit(SET_PAGE_LOADING, false);
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
            if (!safe) {
              this.formData.city_id = null;
              this.formData.area_id = null;
              this.serverData.cities = null;
              this.serverData.areas = null;
            }

            // this.dialog = false;
          });
      }
    },
    async onStateChange(val, safe = false) {
      if (val) {
        this.$store.commit(SET_PAGE_LOADING, true);
        let data = { state: val };
        await ApiService.post("/address/cities/search", data)
          .then((response) => {
            this.serverData.cities = response.data.cities;
            if (!safe) {
              this.formData.area_id = null;
              this.serverData.areas = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.$store.commit(SET_PAGE_LOADING, false);
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
            if (!safe) {
              this.formData.area_id = null;
              this.serverData.areas = null;
            }
            // this.dialog = false;
          });
      }
    },
    async onCityChange(val) {
      if (val) {
        this.$store.commit(SET_PAGE_LOADING, true);
        let data = { city: val };
        await ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.serverData.areas = response.data.areas;
            let i = { ...this.serverData };
            this.serverData = i;
            this.$store.commit(SET_PAGE_LOADING, false);
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
            // this.dialog = false;
          });
      }
    },
  },
};
</script>
